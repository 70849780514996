import { Autocomplete } from "@mantine/core";
import { GoAlert, GoAlertFill, GoCheckCircle, GoQuestion } from "react-icons/go";
import React, { useState, useCallback, useMemo } from "react";
import { AutocompleteProps } from "@mantine/core";
import { useDisclosure } from '@mantine/hooks';
import { Popover, Text } from '@mantine/core';
import { MdOutlineFax, MdOutlineTextSnippet, MdOutlineCloud } from "react-icons/md";

export const GetField: React.FC<{
  label: string;
  data: any;
  isHorizontal?: boolean;
  isError?: boolean;
  onFocus?: (data:any) => void;
  onBlur?: () => void;
}> = ({ label, data, isHorizontal = false, isError = false, onFocus, onBlur }) => {
  const [value, setValue] = useState(data?.value?.toString() || '');
  const [opened, { close, open }] = useDisclosure(false);

  const memoizedOnFocus = useCallback((data:any) => {
    if (onFocus) {
      console.log('ON FOCUS:', data);
      onFocus(data);
    }
  }, [data]);

  const memoizedOnBlur = useCallback(() => {
    if (onBlur) {
      onBlur();
    }
  }, [data]);

  const alternateValues = useMemo(() => {
    let values: string[] = [];
    
    if (data?.value != null) {
      values.push(String(data.value));
    }
    
    if (Array.isArray(data?.alternate_values)) {
      const additionalValues = data.alternate_values
        .filter((value: any) => value != null)
        .map(String);
      values.push(...additionalValues);
    }
    console.log('alternateValues', values);
    
    return Array.from(new Set(values));
  }, [data?.value, data?.alternate_values]);

  const getBorderColor = () => {
    switch (data?.confidence) {
      case 'High':
        return 'border-green-500';
      case 'Medium':
        return 'border-2 border-orange-300';
      case 'Low':
        return 'border-2 border-red-500';
      default:
        return 'border-gray-300';
    }
  };

  const labelClass = `label ${isError ? "text-red-500" : "text-gray-500"} font-bold text-sm ${isHorizontal ? "mr-4" : ""}`;

  return (
    <div key={label} className={`parameter text-md mb-2 ${isHorizontal ? "flex items-center" : ""}`}>
      <div className={`${labelClass} ${isHorizontal ? "mr-4 w-1/3" : "mb-1"}`}>{label}:</div>
      <div className="flex justify-between items-center w-2/3">
        <Autocomplete
          value={value}
          placeholder="Pick value or enter anything"
          data={alternateValues}
          className={`flex-grow ${getBorderColor()}`}
          onChange={(value) => {setValue(value); data.confidence = 'High';}}
          renderOption={({ option }) => (
            <div>{option.value.substring(0, 150)}</div>
          )}
          comboboxProps={{ shadow: 'lg', width: 'auto', radius: 'md', position: 'bottom', withArrow: true }}
          filter={({ options, search }) => 
            options.filter((item) => true)
          }
          styles={{ input: { width: '100%' } }}
          onFocus={() => {
            if(data) {
              memoizedOnFocus(data);
            }
          }}
          onBlur={() => { 
              memoizedOnBlur();
          }}
        />

        <Popover offset={0} width={200} position="right" withArrow shadow="md" opened={opened}>
          <Popover.Target>
            <div className="relative">

            {data === undefined || data.confidence === undefined ? (
              <GoQuestion className="ml-2 flex-shrink-0" size={20} color="lightgray" onMouseEnter={open} onMouseLeave={close} />
            ) : data.confidence === 'High' ? (
              <GoCheckCircle className="ml-2 flex-shrink-0" size={20} color="lightgreen" onMouseEnter={open} onMouseLeave={close} />
            ) : data.confidence === 'Medium' ? (
              <GoAlert className="ml-2 flex-shrink-0" size={20} color="orange" onMouseEnter={open} onMouseLeave={close} />
            ) : data.confidence === 'Low' ? (
              <GoAlertFill className="ml-2 flex-shrink-0" size={20} color="red" onMouseEnter={open} onMouseLeave={close} />
            ) : 
              <GoQuestion className="ml-2 flex-shrink-0" size={20} color="lightgray" onMouseEnter={open} onMouseLeave={close} />
            }
            </div>
          </Popover.Target>
          <Popover.Dropdown style={{ pointerEvents: 'none' }}>
            <Text size="sm" fw={700} className=" mb-1">Sources:</Text>
            <ul className="space-y-1 text-sm">
              {data?.verificationSources && data?.verificationSources.map((source: string, index: number) => {
                const fileName = source.split('/').pop() || source;
                let Icon;
                if (fileName === 'Chart Notes') {
                  Icon = MdOutlineTextSnippet;
                } else if (fileName === 'API') {
                  Icon = MdOutlineCloud;
                } else {
                  Icon = MdOutlineFax;
                }
                return (
                  <li key={index} className="text-gray-700 flex items-center">
                    <Icon className="mr-2 flex-shrink-0" size={16} />
                    {fileName} 
                    {data?.page && <span> (pg {data.page})</span>}
                  </li>
                );
              })}
            </ul>
          </Popover.Dropdown>
        </Popover>
      </div>
    </div>
  );
};