import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  ReactNode,
  useCallback,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getuid } from "process";

interface WebSocketContextType {
  isOpen: boolean;
  error: string | null;
  messages: { [key: string]: any }; // Replace `any[]` with the actual type of messages if known
  uploadUrl: string | null;
  downloadUrl: string | null;
  sendMessage: (message: any) => void; // Adjust `any` based on your message structure
  listDocuments: () => Promise<void>; // Adjust or replace with actual function signature
  getUrlForFile: (fileId: string) => void;
  queryDocumentById: (id: string) => void; // Added this line
}

const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined);

export const WebSocketProvider = ({ children }: { children: ReactNode }) => {

  const websocket = useRef<WebSocket | null>(null);
  const [messages, setMessages] = useState<{ [key: string]: any }>({});
  const [error, setError] = useState<string | null>(null);
  const [uploadUrl, setUploadUrl] = useState<string | null>(null);
  const [downloadUrl, setDownloadUrl] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);

  const websocketUrl = process.env.REACT_APP_WEBSOCKET_URL || "";
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const sendMessage = (message: any) => {
    if (websocket.current && websocket.current.readyState === WebSocket.OPEN) {
      websocket.current.send(message);
    } else {
      console.log("WebSocket is not open. Unable to send message.");
    }
  };

  const getUrlForFile = (fileId: string) => {
    if (websocket.current && websocket.current.readyState === WebSocket.OPEN) {
      console.log("Getting URL for file:", fileId);
      const payload = {
        action: "getDownloadUrl",
        id: fileId,
      };
      websocket.current.send(JSON.stringify(payload));
    } else {
      console.log("WebSocket is not connected");
      setError("WebSocket is not connected");
    }
  };

  const listDocuments = async () => {
    if (websocket.current && websocket.current.readyState === WebSocket.OPEN) {
      const endDate = new Date().toISOString().split("T")[0];
      const startDate = new Date(Date.now() - 10 * 24 * 60 * 60 * 1000)
        .toISOString()
        .split("T")[0];

      const payload = {
        action: "queryDocuments",
        startDate: startDate,
        endDate: endDate,
      };

      websocket.current.send(JSON.stringify(payload));
      console.log("Query payload sent:", payload);
    } else {
      console.log("WebSocket is not connected");
      setError("WebSocket is not connected");
    }
  };

  const queryDocumentById = (id: string) => {
    if (websocket.current && websocket.current.readyState === WebSocket.OPEN) {
      console.log("Querying document by ID:", id);
      const payload = {
        action: "queryDocumentById",
        id: id,
      };
      websocket.current.send(JSON.stringify(payload));
    } else {
      console.log("WebSocket is not connected");
      setError("WebSocket is not connected");
    }
  };

  const connectToWebSocket = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if(isConnecting) {
        console.log("Already connecting to WebSocket via Context");
        resolve();
      }
      setIsConnecting(true);
      console.log("Connecting to WebSocket via Context");
      getAccessTokenSilently().then((accessToken) => {
        const ws = new WebSocket(`${websocketUrl}?access_token=${accessToken}`);
        websocket.current = ws;
        ws.onerror = (event) => {
          console.log("WebSocket error", event);
          setError(`WebSocket error: ${event}`);
          ws.close();
        };
        ws.onopen = (event) => {
          console.log("WebSocket onopen", event);
          if ((event.target as WebSocket).readyState === WebSocket.OPEN) {
            console.log("Connected to WebSocket via Context");
            setIsOpen(true);
            resolve();
          }
        };
        ws.onmessage = (event) => {
          const data = JSON.parse(event.data);
          if (event.data.includes("uploadUrl")) {
            setUploadUrl(data.url);
          } else if (event.data.includes("downloadUrl")) {
            setDownloadUrl(data.url);
          } else {
            setMessages((prevMessages) => ({
              ...prevMessages,
              [data.id]: data,
            }));
          }
        };
        ws.onclose = () => {
          console.log("Disconnected from WebSocket via Context");
          setIsOpen(false);
          setIsConnecting(false);
          websocket.current = null;
        };
      }).catch((error) => {
        console.log("Error connecting to WebSocket via Context", error);
        setIsOpen(false);
        setIsConnecting(false);
        reject(error);
      });
    });
  };

  useEffect(() => {
    if (!websocket.current || websocket.current.readyState === WebSocket.CLOSED) {
      connectToWebSocket();
      return () => {
        websocket.current = null;
      };
    }
  }, []);

  const contextValue = {
    isOpen,
    error,
    messages,
    uploadUrl,
    downloadUrl,
    sendMessage,
    listDocuments,
    getUrlForFile,
    queryDocumentById // Added this line
  };

  return (
    <WebSocketContext.Provider value={contextValue}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = (): WebSocketContextType => {
  const context = useContext(WebSocketContext);
  if (context === undefined) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};