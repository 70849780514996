import React, { useEffect, useState } from "react";
import { useWebSocket } from "../services/WebSocketContext";
import "./MessageTable.css"; // Import the CSS file
import { useNavigate } from "react-router-dom";

const MessageTable: React.FC = () => {
  const { messages, isOpen, listDocuments } = useWebSocket();
  const navigate = useNavigate();

  const sortedMessages = messages
    ? Object.entries(messages).sort(
        ([, a], [, b]) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
    : [];

  const handleClick = (fileId: string) => {
    // navigate(`/docReview?fileId=${fileId}`);
  };

  return (
    <table className="text-sm rounded-lg">
      <thead>
        <tr>
          <th>ID</th>
          <th>Created At</th>
          <th>File Name</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
      {sortedMessages
          .filter(([, message]) => message.status !== 'Completed')
          .map(([id, message]) => (
            <tr
              key={id}
              onClick={() => handleClick(id)}
              className="px-4 text-sm"
            >
              <td>{id}</td>
              <td>{new Date(message.createdAt).toLocaleString()}</td>
              <td>{message.fileKey.replace("upload/", "")}</td>
              <td>{message.status}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default MessageTable;
