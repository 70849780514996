import { ChangeEvent, useEffect, useRef, useState, useCallback } from "react";
import StatusBar from "./StatusBar";
import { useWebSocket } from "../services/WebSocketContext";
import { useDropzone } from 'react-dropzone';

function Upload() {
  const inputRef = useRef<any>(null);
  // const [messages, setMessages] = useState<{ [key: string]: any }>({});
  const [error, setError] = useState<string | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [isUploading, setIsUploading] = useState(false);



  const webSocketContext = useWebSocket();
  const messages = webSocketContext?.messages;
  const wserror = webSocketContext?.error;
  const sendMessage = webSocketContext?.sendMessage;
  const uploadUrl = webSocketContext?.uploadUrl;
  //const setUploadUrl = webSocketContext?.setUploadUrl;

  const kmsKeyId = process.env.REACT_APP_KMS_KEY_ID || "";

  const sendUploadUrlRequest = (filename: string) => {
    if(sendMessage) {
      const payload = {
        action: "getUploadUrl",
        filename: filename
      };
      sendMessage(JSON.stringify(payload));
      console.log('Upload URL request sent:', payload);
    } else {
      console.error('WebSocket is not connected');
      setError('WebSocket is not connected');
    }
  };

  useEffect(() => {
    if(uploadUrl && file) {
      console.log('Upload URL received:', uploadUrl);
      uploadFile(uploadUrl, file);
      // if(setUploadUrl) setUploadUrl(null);
    }
  }, [uploadUrl]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const droppedFile = acceptedFiles[0];
      setFile(droppedFile);
      console.log('File set:', droppedFile.name); // Add this line for debugging
      sendUploadUrlRequest(droppedFile.name);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const uploadFile = async (url: string, file: File) => {
    setIsUploading(true);
    try {
      const response = await fetch(url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
          'x-amz-server-side-encryption': 'aws:kms',
          'x-amz-server-side-encryption-aws-kms-key-id': kmsKeyId,
        },
      });

      if (response.ok) {
        console.log('File uploaded successfully');
        setFile(null);
        setIsUploading(false);

      } else {
        console.error('File upload failed');
        setError('File upload failed');
        setFile(null);
        setIsUploading(false);
      }
    } catch (error) {
      console.error('Error uploading file', error);
      setError('Error uploading file');
      setIsUploading(false);
    }
  };

  return (
    <>
      <div
        className="flex flex-col items-center justify-center h-20 cursor-pointer bg-black text-white rounded-md text-gray-200 hover:shadow-xl"
        data-id="46"
      >
        <div {...getRootProps()} className="dropzone">
          <input {...getInputProps()} />
          
          {file && <p>File selected: {file.name}</p>}
          {isUploading ? <p>Uploading file...</p> :
           <div className="flex flex-col items-center justify-center">
            <svg
              data-id="47"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="w-10 h-10"
            >
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
              <polyline points="17 8 12 3 7 8"></polyline>
              <line x1="12" x2="12" y1="3" y2="15"></line>
            </svg>
            <span className="mt-2">Upload or Drop</span>
          </div>
          }
        </div>
      </div>
      {error && <StatusBar message={error} type="error" />}
    </>
  );
}

export default Upload;