// src/components/pages/LoginPage.tsx
import React from "react";
import { useAuth0 } from "@auth0/auth0-react";


const LoginPage: React.FC<{}> = () => {

  const { loginWithRedirect } = useAuth0();

  return (
    <div className="login-page">
      <button onClick={() => loginWithRedirect()}>Log In</button>
    </div>
  );
};

export default LoginPage;
