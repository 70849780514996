import Upload from "../common/Upload";
import { useNavigate } from "react-router-dom";
import MessageTable from "../common/MessageTable";
import { useWebSocket } from "../services/WebSocketContext";
import { useEffect } from "react";
import { segmentAnalytics } from "../../analytics/segmentAnalytics"

function DiabetesUpload() {
  const navigate = useNavigate();
  const { listDocuments, messages, isOpen } = useWebSocket();

  useEffect(() => {
    segmentAnalytics.page('Upload Documents');
  }, []);

  useEffect(() => {
    if (isOpen === true) {
      listDocuments();
    }

  }, [isOpen]);

  const statusCallback = (fileId: string) => {
    console.log("Status callback called:", fileId);
    navigate(`/docReview?fileId=${fileId}`);
  };

  return (
    <>
      <div className="flex items-center justify-between mb-6" data-id="22">
        <h1 className="text-2xl font-bold text-gray-800" data-id="23">
          Upload Documents
        </h1>
      </div>
      <hr className="w-full border-gray-300"></hr>

      {/* <div className='bg-white rounded-lg p-6 mt-8'>
        <div className='grid grid-cols-3 gap-10'>
          <div className='flex border-r border-gray-100 items-center justify-between'>
            <div className='flex items-center space-x-2'>
              <div className='rounded-full p-8 bg-blue-300'>
                <IconUsers className='' />
              </div>
              <div className='pl-3'>
                <div className='text-lg text-gray-300'>Docs Backlog</div>
                <div className='text-4xl font-bold'>0</div>
              </div>
            </div>
          </div>
          <div className='flex border-r border-gray-100 items-center justify-between'>
            <div className='flex items-center space-x-2'>
              <div className='rounded-full p-8 bg-blue-300'>
                <IconMoney className />
              </div>
              <div className='pl-3'>
                <div className='text-lg text-gray-300'>Money Saved </div>
                <div className='text-4xl font-bold'>0</div>
              </div>
            </div>
          </div>
          <div className='flex items-center justify-between'>
            <div className='flex items-center space-x-2'>
              <div className='rounded-full p-8 bg-blue-300'>
                <IconError className />
              </div>
              <div className='pl-3'>
                <div className='text-lg text-gray-300'>Document Errors</div>
                <div className='text-4xl font-bold'>0</div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="mt-6 flex gap-4">
        <div className="flex-1 rounded-xl shadow-lg bg-black text-white p-6">
          <Upload />
        </div>
        <div className="flex-1 rounded-xl shadow-lg bg-white text-black p-6">
          <div className="text-gray-500 text-sm">Active / Total</div>
          <div className="flex items-baseline pt-4 pb-3">
            <div className="text-2xl font-bold">100</div>
            <div className="text-gray-400 text-base ml-1">/ 1803</div>
          </div>
        </div>
      </div>
      <div className="mt-6 rounded-xl shadow-lg bg-white p-6">
        <div className="text-gray-500 text-sm font-medium pb-5">Uploaded Files</div>
        <MessageTable />
      </div>
    </>
  );
}

export default DiabetesUpload;

// text-white bg-green-500