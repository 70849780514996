// src/App.tsx
import React, { useState, useEffect } from "react";
import "./App.css";
import Layout from "./components/common/Layout";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./components/pages/Dashboard";
import Dme from "./components/pages/Dme";
import Diabetes from "./components/pages/Diabetes";
import LoginPage from "./components/LoginPage";
import ProtectedRoute from "./ProtectedRoute";
import DocumentReview from "./components/pages/DocumentReview";
import { WebSocketProvider } from "./components/services/WebSocketContext";
import { useAuth0 } from "@auth0/auth0-react";
import DiabetesInbox from "./components/pages/DiabetesInbox";
import DiabetesUpload from "./components/pages/DiabetesUpload";
import DiabetesReview from "./components/pages/DiabetesReview";
import DiabetesSubmitted from "./components/pages/DiabetesSubmitted";
import { segmentAnalytics } from "./analytics/segmentAnalytics"

const App: React.FC = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    segmentAnalytics.page('Landing Page');
  }, []);

  useEffect(() => {
    if (isAuthenticated && user) {
      segmentAnalytics.identify(user.sub!, {
        email: user.email ?? '',
        name: user.name ?? '',
      });
    }
  }, [isAuthenticated, user]);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  return (
      <BrowserRouter>
        <div className="App">
          {isAuthenticated ? (
            <WebSocketProvider>
              <Routes>
                <Route
                  path="/"
                  element={<Layout user={user} />}
                >
                  <Route index element={<Dashboard />} />
                  <Route path="dme" element={<ProtectedRoute />}>
                    <Route path="" element={<Dme />} />
                  </Route>
                  <Route path="diabetes" element={<ProtectedRoute />}>
                    <Route index element={<Diabetes />} />
                    <Route path="upload" element={<DiabetesUpload />} />
                    <Route path="inbox" element={<DiabetesInbox />} />
                    <Route path="review" element={<DiabetesReview />} />
                    <Route path="submitted" element={<DiabetesSubmitted />} />
                  </Route>
                  <Route path="docReview" element={<ProtectedRoute />}>
                    <Route path="" element={<DocumentReview />} />
                  </Route>
                </Route>
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </WebSocketProvider>
          ) : (
            <Routes>
              <Route path="*" element={<LoginPage />} />
            </Routes>
          )}
        </div>
      </BrowserRouter>
  );
};

export default App;
