import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout"
import "@react-pdf-viewer/core/lib/styles/index.css"
import "@react-pdf-viewer/default-layout/lib/styles/index.css"
import { Auth0Provider, useAuth0} from "@auth0/auth0-react"

import { createTheme, MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';
import 'mantine-datatable/styles.layer.css';

const theme = createTheme({
  fontFamily: 'Open Sans, sans-serif',
  primaryColor: 'cyan',
});

const AuthWrapper = ({ children }: { children: React.ReactNode }) => {
  return <>{children}</>;
};


const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
  //<React.StrictMode>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: `${window.location.origin}${window.location.pathname}`
      }}
    >
      <AuthWrapper>
        <MantineProvider theme={theme}>
          <App />
        </MantineProvider>
      </AuthWrapper>
    </Auth0Provider>
  //</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()