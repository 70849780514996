import { Accordion } from "@mantine/core";
import React, { useCallback } from "react";
import { ImStack } from "react-icons/im";
import { GoAlert, GoAlertFill, GoCheckCircle, GoQuestion } from "react-icons/go";
import { GetField } from './GetField';

interface PDRAccordionProps {
  document: any;
  fieldFocused: (data:any) => void;
  fieldBlurred: () => void;
}

export const PDRAccordion: React.FC<PDRAccordionProps> = ({
  document,
  fieldFocused,
  fieldBlurred
}) => {

  const pdrTaxonomy: { [key: string]: string } = {
    "subject": "Subject",
    "date": "Date",
    "from": "From",
    "to": "To",
    "from_fax_number": "From Fax Number",
    "from_phone_number": "From Phone Number",
    "from_email": "From Email",
    "provider_name": "Provider Name",
    "patient_name": "Patient Name",
    "patient_dob": "Patient DOB",
    "notes": "Notes",
    "fax_to_number": "Fax To Number",
    "summary": "Summary",
    "additional_info": "Additional Info",
    "respond_to_address": "Respond To Address",
    "respond_to_phone_number": "Respond To Phone Number",
    "respond_to_email": "Respond To Email"
  }

  const renderConfidenceCounts = (sectionKeys: string[]) => {
    const confidenceCounts = sectionKeys
      .reduce((acc, key) => {
        const confidence = document[key]?.confidence;
        if (confidence) {
          acc[confidence] = (acc[confidence] || 0) + 1;
        }
        return acc;
      }, {} as Record<string, number>);

    return (     
      <span className="ml-2 text-sm flex items-center">
        {Object.entries(confidenceCounts).map(([confidence, count]) => (
          <span key={confidence} className="mr-2 flex items-center">
            {confidence === 'High' ? (
              <GoCheckCircle className="mr-1 flex-shrink-0" size={16} color="lightgreen" />
            ) : confidence === 'Medium' ? (
              <GoAlert className="mr-1 flex-shrink-0" size={16} color="orange" />
            ) : confidence === 'Low' ? (
              <GoAlertFill className="mr-1 flex-shrink-0" size={16} color="red" />
            ) : (
              <GoQuestion className="mr-1 flex-shrink-0" size={16} color="lightgray" />
            )}
            {confidence.substring(0,1)}: {count}
          </span>
        ))}
      </span>
    );
  };

  const memoizedFieldBlurred = useCallback(() => {
    fieldBlurred();
  }, [fieldBlurred]);

  const memoizedFieldFocused = useCallback((data:any) => {
    fieldFocused(data);
  }, [fieldFocused]);

  const generateAccordionItem = (
    value: string,
    title: string,
    filterFn: (key: string) => boolean,
    showConfidenceCounts: boolean = false
  ) => (
    <Accordion.Item value={value} key={value}>
      <Accordion.Control icon={<ImStack />}>
        <div className="flex items-center justify-between w-full">
          <span>{title}</span>
          {showConfidenceCounts && renderConfidenceCounts(Object.keys(pdrTaxonomy).filter(filterFn))}
        </div>
      </Accordion.Control>
      <Accordion.Panel>
        {Object.keys(pdrTaxonomy)
          .filter(filterFn)
          .map(key => (
            <div key={`${value}-${key}`}>
              <GetField
                label={pdrTaxonomy[key] || key.replace(`${value.toLowerCase()}_`, '').replace(/_/g, ' ')}
                data={document[key]}
                isHorizontal={true}
                onFocus={memoizedFieldFocused}
                onBlur={memoizedFieldBlurred}
              />
              {/*getField(
                pdrTaxonomy[key] || key.replace(`${value.toLowerCase()}_`, '').replace(/_/g, ' '),
                document[key],
                true
              )*/}
            </div>
          ))
        }
      </Accordion.Panel>
    </Accordion.Item>
  );


  return (
    <Accordion multiple={true} defaultValue={["Patient Data Request"]}>
      {generateAccordionItem(
        "Patient Data Request",
        "Patient Data Request",
        key => true,
        true
      )}
    </Accordion>
  );
};