import React from "react"

const StatusBar: React.FC<{ message: string; type: "success" | "error" }> = ({
  message,
  type,
}) => {
  return (
    <div
      className={`${
        type === "success" ? "bg-green-200" : "bg-red-300"
      } p-4 my-2`}
    >
      {message}
    </div>
  )
}

export default StatusBar
