import React, { useCallback, useEffect, useState, useRef } from "react";
import { Button } from "../common/Button";
import {
  DocumentDetails
} from "../common/DocumentDetails";
import { useLocation, useNavigate } from "react-router-dom";
import { useWebSocket } from "../services/WebSocketContext";
import { CircleLoader } from "react-spinners";
import { FaArrowLeft } from "react-icons/fa";
import { Switch } from '@mantine/core';

// pdf viewer
import { SpecialZoomLevel, Worker } from '@react-pdf-viewer/core';
import { Viewer, DocumentLoadEvent } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { highlightPlugin, Trigger, HighlightArea, RenderHighlightsProps   } from '@react-pdf-viewer/highlight';
import { segmentAnalytics } from "../../analytics/segmentAnalytics"


// Import pdf viewer styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';

function DocumentReview() {
  useEffect(() => {
    segmentAnalytics.page('Document Review');
  }, []);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const fileId = searchParams.get("fileId");
  const documentKey = searchParams.get("documentKey") || '';

  const { messages, isOpen, getUrlForFile, downloadUrl, queryDocumentById } = useWebSocket() || {};

  const [fileRow, setFileRow] = useState<any | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [fileUrl, setFileUrl] = useState("");
  const [document, setDocument] = useState<any | null>(null);
  const [documentDetails, setDocumentDetails] = useState<any | null>(null);

  const [iframeSrc, setIframeSrc] = useState("");
  const [currentHighlight, setCurrentHighlight] = useState<HighlightArea | null>(null);

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    /*props?: DefaultLayoutPluginProps*/
  });

  const pageNavigationPluginInstance = pageNavigationPlugin({
    /*props?: PageNavigationPluginProps*/
  });

  const zoomPluginInstance = zoomPlugin({
    /*props?: ZoomPluginProps*/
  });

  const [pageSize, setPageSize] = useState<{ width: number; height: number } | null>(null);

  const handleDocumentLoad = (e: DocumentLoadEvent) => {
    const doc = e.doc;
    if (doc) {
      const page = doc.getPage(1);  // Get the first page (index 1)
      page.then(p => {
        const viewport = p.getViewport({ scale: 1 });
        setPageSize({ width: viewport.width, height: viewport.height });
      });
    }
  };

  const viewerRef = useRef<HTMLDivElement>(null);

  const fieldFocused = useCallback((data: any) => {
    console.log('Doc Review FIELD FOCUSED:', data);
    if (data?.page) {
      pageNavigationPluginInstance.jumpToPage(data.page - 1);
    }
    if (data?.bounding_boxes && data?.bounding_boxes.length > 0 && data?.bounding_boxes[0].length === 4 && pageSize) {
      console.log('BOUNDING BOXES:', data.bounding_boxes);
      const [[x1, y1], [x2, y2], [x3, y3], [x4, y4]] = data.bounding_boxes[0];
      
      const left = Math.min(x1, x2, x3, x4);
      const top = Math.min(y1, y2, y3, y4);
      const right = Math.max(x1, x2, x3, x4);
      const bottom = Math.max(y1, y2, y3, y4);

      const width = right - left;
      const height = bottom - top;

      console.log('LEFT:', left, 'TOP:', top, 'RIGHT:', right, 'BOTTOM:', bottom);

      const highlight: HighlightArea = {
        pageIndex: data.page - 1,
        height: (height / pageSize.height) * 100, // Convert to percentage
        width: (width / pageSize.width) * 100,    // Convert to percentage
        left: (left / pageSize.width) * 100,      // Convert to percentage
        top: (top / pageSize.height) * 100,       // Convert to percentage
      };

      // Calculate zoom level to make highlight area 100% width
      if (pageSize) {
        const currentWidth = (width / pageSize.width) * 100;
        const zoomFactor = (100 / currentWidth) * 0.8;
        
        // Zoom to the calculated level
        zoomPluginInstance.zoomTo(zoomFactor);
        
        /*
        // Center the view on the highlight
        const centerX = left + width / 2;
        const centerY = top + height / 2;
        const centerPercentX = (centerX / pageSize.width) * 100;
        const centerPercentY = (centerY / pageSize.height) * 100;
        
        // Use setTimeout to ensure zoom is applied before scrolling

        if (viewerRef.current) {
          viewerRef.current.scrollLeft = (centerPercentX / 100) * viewerRef.current.scrollWidth - viewerRef.current.clientWidth / 2;
          viewerRef.current.scrollTop = (centerPercentY / 100) * viewerRef.current.scrollHeight - viewerRef.current.clientHeight / 2;
        }*/
      }

      setCurrentHighlight(highlight);
      highlightPluginInstance.jumpToHighlightArea(highlight);
    }
  }, [pageSize]); // Add pageSize to the dependency array

  const fieldBlurred = useCallback(() => {
    setCurrentHighlight(null);
    zoomPluginInstance.zoomTo(SpecialZoomLevel.PageFit)
  }, []);

  const renderHighlights = useCallback((props: RenderHighlightsProps) => (
    <div>
      {currentHighlight && currentHighlight.pageIndex === props.pageIndex && (
        <div
          className="highlight-area"
          style={Object.assign(
            {},
            {
              border: '3px solid red',
            },
            props.getCssProperties(currentHighlight, props.rotation)
          )}
        />
      )}
    </div>
  ), [currentHighlight]);

  const highlightPluginInstance = highlightPlugin({
    trigger: Trigger.None,
    renderHighlights
  });


  useEffect(() => {
    if (fileUrl && currentPage) {
      // const iframeSrc = `${fileUrl}#toolbar=1&page=${currentPage}`; 
      // console.log('IFRAME SRC:', iframeSrc);
      setIframeSrc(fileUrl);
    }
  }, [fileUrl, currentPage]);

  useEffect(() => {
    if (fileId && isOpen && getUrlForFile) {
      getUrlForFile(fileId);
    }
  }, [fileId, isOpen]);

  useEffect(() => {
    if (downloadUrl) {
      console.log("DOWNLOAD URL:",downloadUrl);
      setFileUrl(downloadUrl);
    }
  }, [downloadUrl]);

  useEffect(() => {
    if (messages) {
      console.log("MESSAGES:",messages);
      if (fileId && messages) {
        const messageForFileId = messages[fileId];
        if (messageForFileId) {
          console.log(`Message for fileId ${fileId}:`, messageForFileId);
          setFileRow(messageForFileId);
        } else {
          // query dynamo
          console.log('Message not found, querying it');
          queryDocumentById(fileId);
        }
      }
    }
  }, [fileId, messages, isOpen]);

  useEffect(() => {
    if (fileRow && documentKey) {
      console.log("FILEROW:",fileRow);

      // Get Document list
      const documentListStr = fileRow['documents'];
      const documentList = JSON.parse(documentListStr);
      console.log('DOCUMENTS', documentList);

      // Get specific document description
      const documentDetails = documentList[documentKey];
      console.log('DOCUMENT DETAILS',documentDetails);
      setDocumentDetails(documentDetails);

      const pageStart = documentDetails.page_start.replace('page_','').replace('.png','');
      setCurrentPage(pageStart);

      // Get specific document
      const documentStr = fileRow[documentKey];
      if(documentStr) {
        const document = JSON.parse(documentStr);
        console.log('DOCUMENT', document);
        setDocument(document);
      } else {
        setDocument({});
      }
    }
  }, [fileRow, documentKey]);

  const navigate = useNavigate();

  return (
    <>
      <div className="flex items-center justify-between mb-6" data-id="22">
        <div className="flex items-center">
          <FaArrowLeft className="cursor-pointer mr-4" onClick={() => navigate(-1)} />
          <h1 className="text-2xl font-bold text-gray-800" data-id="23">
            Document Review
          </h1>
        </div>
        <div className="flex items-center space-x-4">
          <Switch
            label="Ready for review"
            className="mr-4"
            color="indigo"
          />
          <div className="flex items-center space-x-2">
            <button 
              className="w-24 px-3 py-1 text-sm font-medium text-blue-600 bg-blue-50 rounded-md hover:bg-blue-100 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-300"
              onClick={() => {}}
            >
              Previous
            </button>
            <span className="text-gray-400">|</span>
            <button 
              className="w-24 px-3 py-1 text-sm font-medium text-blue-600 bg-blue-50 rounded-md hover:bg-blue-100 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-300"
              onClick={() => {}}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <div className="flex gap-6 h-[calc(100vh-90px)]">
      <div className="w-1/2 rounded-xl shadow-lg bg-white p-6 overflow-auto">
      {/* Left panel content */}
          {document && documentDetails ? (
            <DocumentDetails fieldFocused={fieldFocused} fieldBlurred={fieldBlurred} documentKey={documentKey} document={document} documentDetails={documentDetails} />
          ) : (
            <div className="flex flex-col items-center justify-center h-full">
              <CircleLoader size={80} color="#000000" />
            </div>
          )}
        </div>

        <div className="w-1/2 rounded-xl shadow-lg bg-white p-3">
          {/* Right panel content */}
           {fileId && iframeSrc ? (
              <Worker workerUrl="/pdf.worker.min.js">
                <div ref={viewerRef} className="rpv-core__viewer" style={{ height: '100%', width: '100%' }}>
                  <Viewer 
                    fileUrl={iframeSrc}
                    plugins={[defaultLayoutPluginInstance, pageNavigationPluginInstance, zoomPluginInstance, highlightPluginInstance]}
                    onDocumentLoad={handleDocumentLoad}
                  />
                </div>
              </Worker>
            ) : (
              <div className="flex items-center justify-center h-full">
                <CircleLoader size={80} color="#000000" />
              </div>
            )}
        </div>
      </div>
    </>

    /* 
    <div className="flex flex-col gap-8 h-full">
      {
        (document && documentDetails) ? (
          <div className="h-[95vh] flex flex-col flex-grow  bg-white rounded-2xl shadow-lg">
            <div className="flex justify-between p-4 border-b border-slate-300">
              <div
                className="mr-3 mt-0 text-lg hover:cursor-pointer"
                onClick={() => {
                  // Navigate back to the previous page
                  navigate(-1);
                }}
              >
                &#x2190;
              </div>
              <p className="text-lg text-center mt-1 align-middle font-bold text-gray-600">
                Analyzed Document
              </p>

              <div className="flex gap-4">
                <Button
                  title="Save"
                  customClass="px-6"
                  onClickCallback={() => {
                    alert("Document changes saved");
                  }}
                />
              </div>
            </div>

            <div className="flex w-full overflow-auto h-full">

              <div className="w-[50%] px-8 py-4 flex flex-col gap-4 overflow-auto justify-start">
                {document && (
                  <DocumentDetails documentKey={documentKey} document={document} documentDetails={documentDetails} />
                )}
              </div>

              <div className="border-r h-full border-slate-300 " />

              <div className="w-[50%] flex justify-center">
                {fileId && fileUrl ? (
                  <iframe className="border border-slate-300 h-full w-full" src={fileUrl + `#toolbar=0&view=Fit&page=${currentPage}`}></iframe>
                ) : (
                  <div className="flex items-center justify-center h-full">
                    <p className="text-gray-500">No document available</p>
                  </div>
                )}
              </div>
            </div>            
          </div>
        ) : (
          <div className="h-[95vh] flex flex-col flex-grow bg-white rounded-2xl shadow-lg items-center justify-center">
            <p className="text-gray-500"><CircleLoader size={80} color="#000000" /></p>
            <p className="text-gray-500"><br/>Loading.</p>
          </div>
        )
      }
    </div>*/
  );
}

export default DocumentReview;