import { NavLink } from "react-router-dom";
import Logo from "./Logo";
import IconDashboard from "../icons/Dashboard";
import IconDme from "../icons/Dme";
import IconDiabetes from "../icons/Diabetes";
import { Button } from "./Button";
import { useAuth0 } from "@auth0/auth0-react";

import { FaFolder, FaFolderOpen } from "react-icons/fa";
import { BsLightningChargeFill } from "react-icons/bs";
import { FaSignOutAlt } from "react-icons/fa";


function getClassName(isActive: boolean) {
  return `flex items-center gap-x-3.5 py-2 px-2.5 text-xl font-semibold rounded-lg ${
    isActive
      ? "text-blue-500 bg-blue-100"
      : "bg-white text-neutral-500 hover:bg-gray-100"
  }`;
}

function getChildClassName(isActive: boolean) {
  return `flex-grow flex items-center gap-x-3.5 px-2.5 text-sm rounded-lg ${
    isActive
      ? "bg-blue-100 font-semibold"
      : "bg-white text-gray-500 hover:bg-gray-100"
  }`;
}

function Sidebar({
  user,
}: {
  user: any;
}) {
  const { logout } = useAuth0();

  return (
    <div
      id="application-sidebar"
      className="hs-overlay [--auto-close:lg]
      hs-overlay-open:translate-x-0
      -translate-x-full transition-all duration-300 transform
      w-[260px]
      hidden
      fixed inset-y-0 start-0 z-[60]
      bg-white
      lg:block lg:translate-x-0 lg:end-auto lg:bottom-0
      dark:bg-neutral-800 dark:border-neutral-700
      shadow
      flex flex-col justify-between
      rounded-r-xl"
    >
      <div>
        <div className="px-6 pt-8 pb-4">
          <NavLink to="/">
            <div className="flex items-center space-x-3">
              <span style={{ fontFamily: 'Koulen, sans-serif' }} className="text-4xl text-gray-800">8090</span>
              <div className="flex flex-col">
                <span className="text-lg font-semibold text-gray-700 leading-tight">Document</span>
                <span className="text-lg font-semibold text-gray-700 leading-tight">Understanding</span>
              </div>
            </div>
          </NavLink>
        </div>

        <nav className="px-6 py-4 flex flex-col flex-grow">
          <ul className="space-y-4">
            <li className="mb-6">
              <NavLink 
                to="/diabetes/upload"
                className="flex items-center justify-center space-x-2 px-4 py-3 bg-blue-600 text-white rounded-full hover:bg-blue-700 transition-colors duration-200"
              >
                <BsLightningChargeFill className="flex-shrink-0 w-5 h-5" />
                <span className="text-sm font-medium">Upload Documents</span>
              </NavLink>
            </li>
            <li className="py-2">
              <hr className="border-t border-gray-200 dark:border-gray-700" />
            </li>
            {[
              { to: "/diabetes/inbox", label: "Inbox", count: 256 },
              { to: "/diabetes/review", label: "Ready for Review", count: 89 },
              { to: "/diabetes/submitted", label: "Submitted", count: 115 },
            ].map((item, index) => (
              <li key={index}>
                <NavLink
                  to={item.to}
                  className={({ isActive }) => `
                    flex items-center justify-between px-4 py-2 rounded-lg
                    ${isActive ? 'bg-blue-100 text-blue-600' : 'text-gray-700 hover:bg-gray-100'}
                    transition-colors duration-200
                  `}
                >
                  {({ isActive }) => (
                    <>
                      <div className="flex items-center space-x-3">
                        {isActive ? 
                          <FaFolderOpen className="flex-shrink-0 w-5 h-5" /> 
                        : <FaFolder className="flex-shrink-0 w-5 h-5" />}
                        <span className="text-sm font-medium">{item.label}</span>
                      </div>
                      {item.count && <span className="text-sm text-gray-500">({item.count})</span>}
                    </>
                  )}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </div>

      {user && (
        <div className="mt-auto border-t border-gray-200 p-6">
          <div className="flex items-center space-x-3">
            <img
              src={user.picture}
              alt={user.name}
              className="w-12 h-12 rounded-full"
            />
            <div>
              <p className="text-sm text-gray-500">Welcome back</p>
              <p className="text-lg font-semibold text-gray-800">{user.name}</p>
            </div>
          </div>
          <button
            onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
            className="mt-4 w-full flex items-center justify-center space-x-2 px-4 py-2 text-sm font-medium text-blue-600 bg-blue-50 rounded-lg hover:bg-blue-100 transition-colors"
          >
            <FaSignOutAlt className="w-4 h-4" />
            <span>Logout</span>
          </button>
        </div>
      )}
    </div>
  );
}

export default Sidebar;