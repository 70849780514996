import { AnalyticsBrowser } from '@segment/analytics-next';

const writeKey = process.env.REACT_APP_SEGMENT_WRITE_KEY || '';

export const analytics = AnalyticsBrowser.load({ writeKey });

export const segmentAnalytics = {
  identify: (userId: string, traits: object) => analytics.identify(userId, traits),
  track: (event: string, properties?: object) => analytics.track(event, properties),
  page: (name: string, properties?: object) => analytics.page(name, properties),
};