import { Accordion } from "@mantine/core";
import React from "react";
import { ImStack } from "react-icons/im";
import { GoAlert, GoAlertFill, GoCheckCircle, GoQuestion } from "react-icons/go";
import { GetField } from './GetField';
import { useCallback } from "react";

interface CNAccordionProps {
  document: any;
  fieldFocused: (data:any) => void;
  fieldBlurred: () => void;
}

export const CNAccordion: React.FC<CNAccordionProps> = ({
  document,
  fieldFocused,
  fieldBlurred
}) => {
  const cnTaxonomy: { [key: string]: string } = {
    "patient_first_name": "First Name",
    "patient_last_name": "Last Name",
    "patient_address": "Address",
    "practice_name_facility_center_health_system_provider_name": "Name",
    "patient_primary_diagnosis": "Primary Diagnosis",
    "patient_reason_for_visit": "Reason for Visit",
    "patient_office_visit_date_encounter_date": "Office Visit Date",
    "patient_supplier": "Supplier",
    "patient_mrn": "MRN",
    "patient_home_phone": "Home Phone",
    "patient_cell_phone": "Cell Phone",
    "patient_guarantor": "Guarantor",
    "patient_sex": "Gender",
    "patient_dob": "Date of Birth",
    "patient_insurance_provider_name": "Insurance Provider Name",
    "patient_primary_insurance_subscriber_id": "Insurance ID",
    "insurance_referring": "Insurance Referring",
    "office_visit_informant": "Informant",
    "office_visit_chief_complaint_reason_for_appointment": "Chief Complaint",
    "office_visit_hpi_history_of_present_illness_subjective": "HPI",
    "office_visit_service_date_dos": "Service Date",
    "office_visit_service_time": "Service Time",
    "office_visit_checkout_time": "Checkout Time",
    "physician_nurse_progress_notes_name": "Name",
    "physician_nurse_progress_notes_dob": "DOB",
    "physician_nurse_progress_notes_cc_no": "CC No.",
    "physician_nurse_progress_notes_dos": "Date of Service",
    "medical_history_diagnosis": "Diagnosis",
    "medical_history_previous_illnesses": "Previous Illnesses",
    "medical_history_hospital_diagnostic_procedures": "Hospital Diagnostic Procedures",
    "medical_history_interim_history_new_problems": "New Problems",
    "medical_history_past_surgical_history": "Past Surgical History",
    "family_history_health_conditions": "Family History",
    "social_history_lifestyle_factors": "Lifestyle Factors",
    "social_history_cad_pad_hypertension": "CAD/PAD Hypertension",
    "social_history_reproductive_history": "Reproductive History",
    "social_history_cardio_risk_factors_comorbidities": "Cardio Risk Factors/Comorbidities",
    "medical_history_allergies": "Allergies",
    "medications_taking_prior_to_visit": "Taking Prior to Visit",
    "medications_not_taking": "Not Taking",
    "medications_outpatient": "Outpatient",
    "medications_summary": "Summary",
    "questionnaires_diabetes": "Diabetes",
    "questionnaires_general": "General",
    "diabetes_surveillance": "Diabetes Surveillance",
    "laboratory_glooko_appointment_summaries": "Glooko Appointment Summaries",
    "laboratory_procedure_codes": "Procedure Codes",
    "laboratory_past_orders": "Past Orders",
    "laboratory_hba1c_levels_and_dates": "HBA1C Levels and Dates",
    "laboratory_immunization_history": "Immunization History",
    "laboratory_examination": "Examination",
    "laboratory_screening_other_screens": "Other Screens",
    "impression_plan_instructions_follow_up": "Instructions/Follow Up",
    "impression_plan_preventive_medicine": "Preventive Medicine",
    "documentation_pharmacy_benefits": "Pharmacy Benefits",
    "documentation_vital_signs_vitals": "Vital Signs/Vitals",
    "documentation_orders_placed": "Orders Placed",
    "documentation_flowsheets": "Flowsheets ",
    "documentation_smart_forms": "Smart Forms",
    "documentation_other_concerns": "Other Concerns",
    "documentation_complications_comorbidities": "",
    "documentation_medication_changes": "Medication Changes",
    "documentation_travel_screening": "Travel Screening",
    "disease_screening": "Disease Screening",
    "medication_list_end_of_visit": "Medication List",
    "visit_diagnoses": "Diagnoses",
    "electronically_signed_time": "Signed Time",
    "electronically_signed_by": "Signed By",
    "sign_off_status": "Sign Off Status",
    "sign_off_status_completed": "Sign Off Status Completed"
  }

  const renderConfidenceCounts = (sectionKeys: string[]) => {
    const confidenceCounts = sectionKeys
      .reduce((acc, key) => {
        const confidence = document[key]?.confidence;
        if (confidence) {
          acc[confidence] = (acc[confidence] || 0) + 1;
        }
        return acc;
      }, {} as Record<string, number>);

    return (     
      <span className="ml-2 text-sm flex items-center">
        {Object.entries(confidenceCounts).map(([confidence, count]) => (
          <span key={confidence} className="mr-2 flex items-center">
            {confidence === 'High' ? (
              <GoCheckCircle className="mr-1 flex-shrink-0" size={16} color="lightgreen" />
            ) : confidence === 'Medium' ? (
              <GoAlert className="mr-1 flex-shrink-0" size={16} color="orange" />
            ) : confidence === 'Low' ? (
              <GoAlertFill className="mr-1 flex-shrink-0" size={16} color="red" />
            ) : (
              <GoQuestion className="mr-1 flex-shrink-0" size={16} color="lightgray" />
            )}
            {confidence.substring(0,1)}: {count}
          </span>
        ))}
      </span>
    );
  };

  const memoizedFieldBlurred = useCallback(() => {
    fieldBlurred();
  }, [fieldBlurred]);

  const memoizedFieldFocused = useCallback((data:any) => {
    fieldFocused(data);
  }, [fieldFocused]);

  const generateAccordionItem = (
    value: string,
    title: string,
    filterFn: (key: string) => boolean,
    showConfidenceCounts: boolean = false
  ) => (
    <Accordion.Item value={value} key={value}>
      <Accordion.Control icon={<ImStack />}>
        <div className="flex items-center justify-between w-full">
          <span>{title}</span>
          {showConfidenceCounts && renderConfidenceCounts(Object.keys(cnTaxonomy).filter(filterFn))}
        </div>
      </Accordion.Control>
      <Accordion.Panel>
        {Object.keys(cnTaxonomy)
          .filter(filterFn)
          .map(key => (
            <div key={`${value}-${key}`}>
              <GetField
                label={cnTaxonomy[key] || key.replace(`${value.toLowerCase()}_`, '').replace(/_/g, ' ')}
                data={document[key]}
                isHorizontal={true}
                onFocus={memoizedFieldFocused}
                onBlur={memoizedFieldBlurred}
              />
              {/*getField(
                cnTaxonomy[key] || key.replace(`${value.toLowerCase()}_`, '').replace(/_/g, ' '),
                document[key],
                true
              )*/}
            </div>
          ))
        }
      </Accordion.Panel>
    </Accordion.Item>
  );

  return (
    <Accordion multiple={true} defaultValue={["Patient Information", "Office Visit", "Physician/Nurse Progress Notes", "Medical History", "Laboratory and Diagnostics", "Questionnaires", "Impression and Plan", "Documentation", "Sign Off"]}>
      {generateAccordionItem(
        "Patient Information",
        "Patient Information",
        key => key.startsWith('patient') || key.startsWith('insurance'),
        true
      )}
      {generateAccordionItem(
        "Office Visit",
        "Office Visit",
        key => key.startsWith('office_visit')
      )}
      {generateAccordionItem(
        "Physician/Nurse Progress Notes",
        "Physician/Nurse Progress Notes",
        key => key.startsWith('physician_nurse_progress_notes')
      )}
      {generateAccordionItem(
        "Medical History",
        "Medical History",
        key => key.startsWith('medical_history') || key.startsWith('family_history') || key.startsWith('social_history')
      )}
      {generateAccordionItem(
        "Medications",
        "Medications",
        key => key.startsWith('medications') || key === 'medication_list_end_of_visit'
      )}
      {generateAccordionItem(
        "Laboratory and Diagnostics",
        "Laboratory and Diagnostics",
        key => key.startsWith('laboratory') || key === 'diabetes_surveillance' || key === 'disease_screening'
      )}
      {generateAccordionItem(
        "Questionnaires",
        "Questionnaires",
        key => key.startsWith('questionnaires')
      )}
      {generateAccordionItem(
        "Impression and Plan",
        "Impression and Plan",
        key => key.startsWith('impression_plan')
      )}
      {generateAccordionItem(
        "Documentation",
        "Documentation",
        key => key.startsWith('documentation') || key === 'visit_diagnoses'
      )}
      {generateAccordionItem(
        "Sign Off",
        "Sign Off",
        key => key.startsWith('electronically_signed') || key.startsWith('sign_off_status')
      )}

    </Accordion>
  );
};