import Upload from "../common/Upload";
import IconError from "../icons/Error";
import IconMoney from "../icons/Money";
import IconSearch from "../icons/Search";
import IconUsers from "../icons/Users";
import Socketconnect from "../common/Socketconnect";
import StatusProgress from "../common/StatusProgress";
import { useNavigate } from "react-router-dom";
import MessageTable from "../common/MessageTable";

function Diabetes() {
  const navigate = useNavigate();

  const statusCallback = (fileId: string) => {
    console.log("Status callback called:", fileId);
    navigate(`/docReview?fileId=${fileId}`);
  };

  return (
    <>
      <div className="flex items-center justify-between mb-6" data-id="22">
        <h1 className="text-xl font-bold" data-id="23">
          Diabetes Unit
        </h1>
      </div>
      <hr className="w-full border-gray-300"></hr>

      <div className="mt-3 flex justify-start py-4">
        <select className="bg-white text-gray-700 py-2 shadow-lg   px-4 pr-8 rounded-xl focus:outline-none focus:bg-white focus:border-gray-500">
          <option>Timeframe: All-time</option>
          <option>Timeframe: This week</option>
          <option>Timeframe: Last week</option>
          <option>Timeframe: Last 30 days</option>
          <option>Timeframe: Last 60 days</option>
          <option>Timeframe: Last 90 days</option>
        </select>
      </div>

      <div className="grid grid-cols-6 gap-4">
        <div className="bg-white p-4 rounded-xl shadow-lg">
          <div className="text-gray-500 text-sm">Active Processing</div>
          <div className="flex items-end pt-4 pb-12">
            <div className="text-2xl font-bold">100</div>
            <div className="text-gray-400 text-sm ml-1">/1803</div>
          </div>
        </div>
        <div className="bg-white p-4 rounded-xl shadow-lg">
          <div className="text-gray-500 text-sm">Documents Processed</div>
          <div className="flex items-end pt-4 pb-12">
            <div className="text-2xl font-bold">3298</div>
          </div>
        </div>
        <div className="bg-white p-4 rounded-xl shadow-lg">
          <div className="text-gray-500 text-sm">Avg. Processing Time</div>
          <div className="flex items-end pt-4 pb-12">
            <div className="text-2xl font-bold">2m 34s</div>
          </div>
        </div>
        <div className="bg-white p-4 rounded-xl shadow-lg col-span-3 row-span-2">
          <div className="text-gray-500 text-sm">Document Confidence</div>

          <div className="text-gray-500 pt-4 text-sm font-bold">Certificate of Medical Necessity</div>
          <div className="flex items-center pt-0 pb-6">
            <div className="flex-grow mr-2">
              <div className="w-full bg-blue-100 rounded-full h-2.5">
                <div className="bg-blue-500 h-2.5 rounded-full" style={{ width: '95.9%' }}></div>
              </div>
            </div>
            <div className="text-sm text-gray-400 whitespace-nowrap">95.9% Correct</div>
          </div>

          <div className="text-gray-500 text-sm font-bold">Chart Notes</div>
          <div className="flex items-center pt-0 pb-6">
            <div className="flex-grow mr-2">
              <div className="w-full bg-orange-100 rounded-full h-2.5">
                <div className="bg-orange-500 h-2.5 rounded-full" style={{ width: '65.2%' }}></div>
              </div>
            </div>
            <div className="text-sm text-gray-400 whitespace-nowrap">65.2% Correct</div>
          </div>

          
          <div className="text-gray-500 text-sm font-bold">Fax Cover Sheets</div>
          <div className="flex items-center pt-0 pb-6">
            <div className="flex-grow mr-2">
              <div className="w-full bg-blue-100 rounded-full h-2.5">
                <div className="bg-blue-500 h-2.5 rounded-full" style={{ width: '83.3%' }}></div>
              </div>
            </div>
            <div className="text-sm text-gray-400 whitespace-nowrap">83.3% Correct</div>
          </div>

        </div>
        <div className="bg-white p-4 rounded-xl shadow-lg">
          <div className="text-gray-500 text-sm">Avg. Confidence</div>
          <div className="flex items-end pt-4 pb-12">
            <div className="text-2xl font-bold">95.9%</div>
          </div>
        </div>
        <div className="bg-white p-4 rounded-xl shadow-lg">
          <div className="text-gray-500 text-sm">Avg. Accuracy</div>
          <div className="flex items-end pt-4 pb-12">
            <div className="text-2xl font-bold">93.2%</div>
          </div>
        </div>
      <div className="bg-white p-4 rounded-xl shadow-lg col-span-3 row-span-2">
          <div className="text-gray-500 text-sm">Avg. Speed</div>
          <div className="flex items-end pt-4 pb-12">
            <div className="text-2xl font-bold">n/a</div>
          </div>
        </div>
      </div>

      {/* <div className='bg-white rounded-lg p-6 mt-8'>
        <div className='grid grid-cols-3 gap-10'>
          <div className='flex border-r border-gray-100 items-center justify-between'>
            <div className='flex items-center space-x-2'>
              <div className='rounded-full p-8 bg-blue-300'>
                <IconUsers className='' />
              </div>
              <div className='pl-3'>
                <div className='text-lg text-gray-300'>Docs Backlog</div>
                <div className='text-4xl font-bold'>0</div>
              </div>
            </div>
          </div>
          <div className='flex border-r border-gray-100 items-center justify-between'>
            <div className='flex items-center space-x-2'>
              <div className='rounded-full p-8 bg-blue-300'>
                <IconMoney className />
              </div>
              <div className='pl-3'>
                <div className='text-lg text-gray-300'>Money Saved </div>
                <div className='text-4xl font-bold'>0</div>
              </div>
            </div>
          </div>
          <div className='flex items-center justify-between'>
            <div className='flex items-center space-x-2'>
              <div className='rounded-full p-8 bg-blue-300'>
                <IconError className />
              </div>
              <div className='pl-3'>
                <div className='text-lg text-gray-300'>Document Errors</div>
                <div className='text-4xl font-bold'>0</div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default Diabetes;

// text-white bg-green-500
