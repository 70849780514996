import { Accordion } from "@mantine/core";
import React, { useCallback } from "react";
import { ImStack } from "react-icons/im";
import { GoAlert, GoAlertFill, GoCheckCircle, GoQuestion } from "react-icons/go";
import { GetField } from './GetField';

interface FaxAccordionProps {
  document: any;
  fieldFocused: (data:any) => void;
  fieldBlurred: () => void;
}

export const FaxAccordion: React.FC<FaxAccordionProps> = ({
  document,
  fieldFocused,
  fieldBlurred
}) => {

  const memoizedFieldBlurred = useCallback(() => {
    fieldBlurred();
  }, [fieldBlurred]);

  const memoizedFieldFocused = useCallback((data:any) => {
    fieldFocused(data);
  }, [fieldFocused]);

  const faxTaxonomy: { [key: string]: string } = {
    "date": "Date",
    "subject": "Subject",
    "from_facility": "From Facility",
    "from_facility_address": "From Facility Address",
    "to_company": "To Company",
    "from_fax_number": "From Fax Number",
    "form_facility": "Form Facility",
    "support_contact_user": "Support Contact User",
    "number_of_pages": "Number of Pages",
    "email": "Email",
    "phone": "Phone",
    "time": "Time",
    "attn": "Attn",
    "notes_for": "Notes For",
    "dob": "DOB",
    "provider_portal": "Provider Portal",
    "direct_messaging_from_emr": "Direct Messaging From EMR",
    "request_for_chart_notes": "Request For Chart Notes",
    "physician_name": "Physician Name",
    "patient_name": "Patient Name",
    "patient_dob": "Patient DOB",
    "please_fax_to": "Please Fax To"
  }

  const renderConfidenceCounts = (sectionKeys: string[]) => {
    const confidenceCounts = sectionKeys
      .reduce((acc, key) => {
        const confidence = document[key]?.confidence;
        if (confidence) {
          acc[confidence] = (acc[confidence] || 0) + 1;
        }
        return acc;
      }, {} as Record<string, number>);

    return (     
      <span className="ml-2 text-sm flex items-center">
        {Object.entries(confidenceCounts).map(([confidence, count]) => (
          <span key={confidence} className="mr-2 flex items-center">
            {confidence === 'High' ? (
              <GoCheckCircle className="mr-1 flex-shrink-0" size={16} color="lightgreen" />
            ) : confidence === 'Medium' ? (
              <GoAlert className="mr-1 flex-shrink-0" size={16} color="orange" />
            ) : confidence === 'Low' ? (
              <GoAlertFill className="mr-1 flex-shrink-0" size={16} color="red" />
            ) : (
              <GoQuestion className="mr-1 flex-shrink-0" size={16} color="lightgray" />
            )}
            {confidence.substring(0,1)}: {count}
          </span>
        ))}
      </span>
    );
  };

  const generateAccordionItem = (
    value: string,
    title: string,
    filterFn: (key: string) => boolean,
    showConfidenceCounts: boolean = false
  ) => (
    <Accordion.Item value={value} key={value}>
      <Accordion.Control icon={<ImStack />}>
        <div className="flex items-center justify-between w-full">
          <span>{title}</span>
          {showConfidenceCounts && renderConfidenceCounts(Object.keys(faxTaxonomy).filter(filterFn))}
        </div>
      </Accordion.Control>
      <Accordion.Panel>
        {Object.keys(faxTaxonomy)
          .filter(filterFn)
          .map(key => (
            <div key={`${value}-${key}`}>
              <GetField
                label={faxTaxonomy[key] || key.replace(`${value.toLowerCase()}_`, '').replace(/_/g, ' ')}
                data={document[key]}
                isHorizontal={true}
                onFocus={memoizedFieldFocused}
                onBlur={memoizedFieldBlurred}
              />
              {/*getField(
                cnTaxonomy[key] || key.replace(`${value.toLowerCase()}_`, '').replace(/_/g, ' '),
                document[key],
                true
              )*/}
            </div>
          ))
        }
      </Accordion.Panel>
    </Accordion.Item>
  );

  return (
    <Accordion multiple={true} defaultValue={["Fax Information"]}>
      {generateAccordionItem(
        "Fax Information",
        "Fax Information",
        key => true,
        true
      )}
    </Accordion>
  );
};