import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useWebSocket } from "../services/WebSocketContext";
import { Table, TableData } from '@mantine/core';
import { NavLink } from "react-router-dom";
import { DataTable } from "mantine-datatable";
import { segmentAnalytics } from "../../analytics/segmentAnalytics"

interface Record {
  id: React.ReactNode;
  fileName: string;
  docNumber: string;
  classification: string;
  uploadedDate: string;
  extractionConfidence: string;
}

function DiabetesReview() {
  const navigate = useNavigate();
  const { listDocuments, messages, isOpen } = useWebSocket();
  const [records, setRecords] = useState<any[]>([]);

  const [tableData, setTableData] = useState<TableData>({
    head: ['ID','File Name', 'Doc#', 'Classification', 'Uploaded Date', 'Extraction Confidence'],
    body: []
  });

  useEffect(() => {
    segmentAnalytics.page('Review Diabetes Documents');
  }, []);

  useEffect(() => {
    if (isOpen === true) {
      listDocuments();
    }
  }, [isOpen]);

  useEffect(() => {
    console.log("New Messages:", messages);
    let tableRows: any[] = [];
    
    if(Object.keys(messages).length > 0) {
      let items = Object.values(messages).filter((message: any) => message.status === 'ReadyToSubmit').flatMap((message: any) => {
        if(message.documents) {
          let documentDetails = JSON.parse(message.documents);
          return Object.entries(documentDetails).map(([key, value]: [string, any]) => {
            const docNumber = key.replace('document_', '');
            if(message[key]) {
              const fields = JSON.parse(message[key]);
              return {
                id: message.id + '-' + docNumber,
                patientName: (fields.patient_first_name?.value || ' ') + ' ' + (fields.patient_last_name?.value || ' '),
                fileId: message.id,
                key: key,
                fileName: message.fileKey.split('/').pop() || '',
                docNumber: docNumber,
                classification: value.classification ? 
                  (value['secondary-classification'] ? 
                    `${value.classification} > ${value['secondary-classification']}` : 
                    value.classification
                  ) : '',              
                uploadedDate: new Date(message.createdAt).toLocaleString() || '',
                createdAt: message.createdAt,
                extractionConfidence: value.extraction_confidence || ''
              };
            }
            return {
              id: message.id + '-' + docNumber,
              patientName: '-',
              fileId: message.id,
              key: key,
              fileName: message.fileKey.split('/').pop() || '',
              docNumber: docNumber,
              classification: value.classification ? 
                (value['secondary-classification'] ? 
                  `${value.classification} > ${value['secondary-classification']}` : 
                  value.classification
                ) : '',              
              uploadedDate: new Date(message.createdAt).toLocaleString() || '',
              createdAt: message.createdAt,
              extractionConfidence: value.extraction_confidence || ''
            };
            
          });
        }
        return []; // Return an empty array if message.documents is falsy
      });
      tableRows = [...tableRows, ...items];
    }

    tableRows.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

    setRecords(tableRows);

    const table: TableData = {
      head: ['ID','File Name', 'Doc#', 'Classification', 'Uploaded Date', 'Extraction Confidence'],
      body: tableRows
    };
    setTableData(table);
  }, [messages]);

  const statusCallback = (fileId: string) => {
    console.log("Status callback called:", fileId);
    navigate(`/docReview?fileId=${fileId}`);
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between mb-6" data-id="22">
        <h1 className="text-xl font-bold" data-id="23">
          Review (Documents Ready to Submit)
        </h1>
      </div>

      <div className="flex-grow rounded-xl shadow-lg bg-white p-6 pt-0 mb-6 overflow-hidden">
        <DataTable
          height="100%"
          textSelectionDisabled
          highlightOnHover
          verticalSpacing="md"
          records={records}
          noRecordsText=""
          noRecordsIcon={
            <div></div>
          }
          columns={[
            {
              accessor: 'patientName',
              title: 'Patient Name',
            },
            { accessor: 'fileName' },
            { accessor: 'docNumber' },
            { accessor: 'classification', sortable: true },
            { accessor: 'uploadedDate', sortable: true  },
            { accessor: 'submit', sortable: true  },
          ]}
          onRowClick={({ record, index, event }) => {
            console.log("Row clicked:", record);
            segmentAnalytics.track('document-review-clicked', {
              fileId: record.fileId,
              documentKey: record.key,
              fileName: record.fileName,
              classification: record.classification
            });
            navigate(`/docReview?fileId=${record.fileId}&documentKey=${record.key}`);
          }}
        />
      </div>
    </div>
  );
}

export default DiabetesReview;

// text-white bg-green-500