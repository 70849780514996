import React, { useState, useCallback } from "react";

import { PrescriptionAccordion } from './PrescriptionAccordion';
import { CNAccordion } from './CNAccordion';
import { FaxAccordion } from './FaxAccordion';
import { PDRAccordion } from './PDRAccordion';

export interface TaxonomyData {
  [key: string]: string;
}

export const DocumentDetails: React.FC<{ documentKey: string, document: any, documentDetails: any, fieldFocused: (data:any) => void, fieldBlurred: () => void }> = ({
  documentKey, document, documentDetails, fieldFocused, fieldBlurred
}) => {

  const renderRow = (title: string, content: string | React.ReactNode) => (
    <div key={title} className="mb-2 py-2">
      <div className="text-sm ">
        <b>{title}</b>
      </div>
      {" "}
      {content}
    </div>
  );

  return (
    <div>
      <div key={documentKey} className="mb-4">
        <div className="flex justify-between items-center mb-4 p-4 pb-0 pt-0 rounded-lg">
          <div className="flex items-center">
            <span className="text-sm font-bold mr-2">Type:</span>
            <span className="text-sm px-2 py-1 rounded">
              {documentDetails.classification}
              {documentDetails['secondary-classification'] && 
                ` > ${documentDetails['secondary-classification']}`}
            </span>          
          </div>
          <div className="flex items-center">
            <span className="text-sm font-bold mr-2">Pages:</span>
            <span className="text-sm  px-2 py-1 rounded">
              {documentDetails.page_end.replace('page_', '').replace('.png','') - documentDetails.page_start.replace('page_', '').replace('.png','') + 1}
            </span>
          </div>
        </div>
        {documentDetails.classification === "prescription" && (
          <PrescriptionAccordion
            document={document}
            fieldFocused={fieldFocused}
            fieldBlurred={fieldBlurred}
          />
        )}
        {documentDetails.classification === "cn" && (
          <CNAccordion
            document={document}
            fieldFocused={fieldFocused}
            fieldBlurred={fieldBlurred}
          />
        )}
        {documentDetails.classification === "faxsheet" && (
          <FaxAccordion
            document={document}
            fieldFocused={fieldFocused}
            fieldBlurred={fieldBlurred}
          />
        )}
        {documentDetails.classification === "pdr" && (
          <PDRAccordion
            document={document}
            fieldFocused={fieldFocused}
            fieldBlurred={fieldBlurred}
          />
        )}
      </div>
    </div>
  );
};